body {
    margin: 0;
    padding: 0;
}

* {
    box-sizing: border-box;
}
.radio input[type="radio"] {
    position: absolute;
    opacity: 0;
  }
  .radio input[type="radio"] + .radio-label:before {
    content: '';
    background: #f4f4f4;
    border-radius: 100%;
    border: 1px solid #bcbcbc; /* darken($color1, 25%) */
    display: inline-block;
    width: 1.3em;
    height: 1.3em;
    position: relative;
    margin-right: 0.2em;
    /* vertical-align: top; */
    cursor: pointer;
    text-align: center;
    transition: all 250ms ease;
  }
  .radio input[type="radio"]:checked + .radio-label:before {
    background-color: #F53C32;
    box-shadow: inset 0 0 0 4px #f4f4f4;
  }
  .radio input[type="radio"]:focus + .radio-label:before {
    outline: none;
    border-color: #F53C32;
  }
  
  .radio input[type="radio"] + .radio-label:empty:before {
    margin-right: 0;
  }
  
  .select-arrow {
    
  width: 100%;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAUCAMAAACtdX32AAAAdVBMVEUAAAD///8AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAhMdQaAAAAJ3RSTlMAAAECAwQGBwsOFBwkJTg5RUZ4eYCHkJefpaytrsXGy8zW3+Do8vNn0bsyAAAAYElEQVR42tXROwJDQAAA0Ymw1p9kiT+L5P5HVEi3qJn2lcPjtIuzUIJ/rhIGy762N3XaThqMN1ZPALsZPEzG1x8LrFL77DHBnEMxBewz0fJ6LyFHTPL7xhwzWYrJ9z22AqmQBV757MHfAAAAAElFTkSuQmCC);
  background-position: 100%;
  background-repeat: no-repeat;
  
  }

  .containers{

    
  }


.one {
    border: 1px solid black;
    padding: 0.25rem; /* Equivalent to Tailwind's p-1 */
    display: flex; /* Equivalent to Tailwind's flex */
    flex-direction: row; /* Equivalent to Tailwind's flex-row */
    justify-content: space-between; /* Equivalent to Tailwind's justify-between */
    border-radius: 0.5rem; /* Equivalent to Tailwind's rounded-t-lg */
    margin-top: 0.75rem; /* Equivalent to Tailwind's mt-3 */
    
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  
  .header{
    display: flex; 
    padding: 0.75rem; 
    flex-direction: row; 
    justify-content: space-between; 
  }

  .radio-span{
    padding-left: 0.5rem; 
    font-size: 1.125rem;
    line-height: 1.75rem; 

  }

  .inner-container{
    border-width: 1px; 
    border-top-width: 0; 
    border: 1px solid black;
    border-top: 0; 
  }

  .select-container{display: flex; 
    padding: 1.25rem; 
    padding-left: 1rem;
    padding-right: 1rem; 
    flex-direction: column; 
    justify-content: space-between; 
    

    }

    .w-full{
        width: 100%;
    }

    .pickup-label{
        display: block; 
        font-size: 0.875rem;
        line-height: 1.25rem; 
        font-weight: 200; 

    }

    .pickup-container{
        display: flex; 
        padding: 1rem; 
        padding-left: 0.75rem;
        padding-right: 0.75rem; 
        padding-right: 0.25rem; 
        margin-top: 0.5rem; 
        flex-direction: row; 
        justify-content: space-between; 
        border-radius: 0.375rem; 
        border-width: 1px; 
        width: 100%; 
        white-space: nowrap;
        border: #e2dcdc 1px solid; 

    }

    .pickup-unordered{
        padding: 0; 
        margin-top: 0.5rem; 
        border-radius: 0.375rem; 
        border-width: 1px; 
        width: 100%; 
        list-style: none;
        border: #e2dcdc 1px solid;

    }

    .pickup-list{

        padding: 1rem; 
        font-size: 1rem;
        line-height: 1.5rem; 

    }

    .pickup-list:hover{
        background: #ECECEC;
    }

    .pickupday-label{
        display: block; 
        font-size: 0.875rem;
        line-height: 1.25rem; 
        font-weight: 200; 

    }

    .coffee-small{
        display: flex; 
        padding: 0.75rem; 
        padding-top: 1rem;
        padding-bottom: 1rem; 
        flex-direction: row; 
        justify-content: space-between; 
        border-radius: 0.375rem; 
        width: 100%; 
        background: #ECECEC;
    }

    .coffee-small h3{
        margin-block-start: 5px;
        margin-block-end: 5px;
    }

    .ul-collection{
        margin-block-start: 0;
        padding-block-start: 0;
        padding-inline-start: 0;
    }

    .ul-collection li{

        padding-top: 5px;
        padding-bottom: 5px;
    }

    .collection{
        display: flex; 
        flex-direction: row; 
        justify-content: space-between; 
    }

    .button-primary{
        padding: 1.25rem; 
        padding-left: 0;
        padding-right: 0; 
        margin-top: 0.75rem; 
        border-radius: 0.375rem; 
        text-align: center; 
        color: #ffffff;
        background: #F53C32;
        border: none; 

    }

    .last-p{
        margin-top: 0.75rem; 
        color: #000000;
        display: flex;
        flex-direction: row;
        justify-content: center; 
    }

    .px54{
        padding-left: 1rem;
        padding-right: 1rem; 
    }

    .hidden{
        display: none;
    }

    .p-1{ 
        padding: 0.25rem;
    }
    .p-2{
        padding: 0.5rem;
    }

    .p-3{

        padding: 0.75rem;
    }

    .p-4{
        padding: 1rem;
    }

    .p-5{
        padding: 1.25rem;
    }

    .mt-1{ 
        margin-top: 0.25rem;
    }
    .mt-2{
        margin-top: 0.5rem;
    }

    .mt-3{

        margin-top: 0.75rem;
    }

    .mt-4{
        margin-top: 1rem;
    }

    .mt-5{
        margin-top: 1.25rem;
    }

    .ml-3{
        padding-left: 0.75rem;
    }

    .px-3{
        padding-left: 0.75rem;
        padding-right: 0.75rem; 

    }

    ul{
        list-style: none;
    }
    .block{
        display: block;
    }











    @media (min-width: 768px) { 

        .select-container{
            flex-direction: row; 
            gap: 0.75rem;
        }
         
    }

    @media (max-width: 768px) { 

        .select-container{
            flex-direction: row; 
            gap: 0.75rem;
        }
         
    }
      
      
      
  @media (min-width: 768px) { 
    .radio-span{
        white-space: nowrap; 
    }
   }

   @media (min-width: 768px) { 
    .last-p{
        text-align: center; 
    }
   }
  